<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Specific Heat of a Metal</h2>

      <p class="mb-2">
        a) If small pieces of copper metal are placed in a thermos cup calorimeter containing
        <number-value :value="massWater" unit="\text{g}" />
        of water, how many degrees Celsius does the water warm up if the specific heat of water is
        <stemble-latex content="$\text{4.184 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}$" />
        and the amount of heat released from the copper to the water is
        <number-value :value="heatFromCopper" unit="\text{cal?}" />
        Assume that the heat lost by the copper equals the heat gained by the water.
      </p>

      <calculation-input
        v-model="inputs.tempChange"
        class="mb-5"
        prepend-text="$\Delta\text{T:}$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) The heat lost by the metal will be
        <stemble-latex content="$\rule{4cm}{0.15mm}$" />
        by the water.
      </p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3 mt-2">
        c) What are you trying to determine the specific heat of in this experiment?
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) What do you need to calculate to discuss in the error analysis for this experiment?
      </p>

      <p v-for="option in options4" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input4" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n3 mt-3">e) How many trials do you have to perform?</p>

      <v-radio-group v-model="inputs.input5" class="ml-6 mb-n4" :disabled="!allowEditing">
        <v-radio
          v-for="option in options5"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'HawkeyePrelabQuiz6',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        tempChange: null,
        input2: null,
        input3: null,
        input4: [],
        input5: null,
      },
      options2: [
        {text: '$\\text{Transmitted}$', value: 'a'},
        {text: '$\\text{Released}$', value: 'b'},
        {text: '$\\text{Gained}$', value: 'c'},
        {text: '$\\text{Lost}$', value: 'd'},
      ],
      options3: [
        {text: '$\\text{Water}$', value: 'a'},
        {text: '$\\text{Copper and/or other metal}$', value: 'b'},
        {text: '$\\text{Calorimeter}$', value: 'c'},
        {text: '$\\text{None of the above}$', value: 'd'},
      ],
      options4: [
        {text: '$\\text{Percent Error}$', value: 'a'},
        {text: '$\\text{Percent Relative Standard Deviation}$', value: 'b'},
        {text: '$\\text{R}^2\\text{ value from a graph}$', value: 'c'},
        {text: '$\\text{Change in mass}$', value: 'd'},
      ],
      options5: [
        {text: '$2$', value: '2'},
        {text: '$3$', value: '3'},
        {text: '$4$', value: '4'},
        {text: '$5$', value: '5'},
      ],
    };
  },
  computed: {
    massWater() {
      return this.taskState.getValueBySymbol('massWater').content;
    },
    heatFromCopper() {
      return this.taskState.getValueBySymbol('heatFromCopper').content;
    },
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
